import "focus-visible";
import React from "react";
import { Helmet } from "react-helmet";
import { Link, withAssetPrefix, graphql } from "gatsby";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "components/Layout";
import Title from "components/Title";
import "pages/index.scss";

export default function IndexPage() {
	const { t } = useTranslation();
	const { language } = useI18next();
	const crumbs = [
		{ pathname: `/${language}`, label: "home" }
	];

	return (
		<Layout title={t('project-name')} crumbs={crumbs}>
			<Helmet>
				<meta name="DCSext.cg_s_s" content="Languages take you further" />
			</Helmet>
			<Title
				title={t('index.title')}
				content={t('index.content')}
			></Title>
			<div className="index-page-content">
				<img src={withAssetPrefix(`/images/index-page/LTYF-Homepage-Visual-${language}.svg`)} alt={t('project-name')} loading="lazy"></img>
				<Link to={`/${language}/discover`} className="ecl-button ecl-button--call">{t('discover.title')}</Link>
			</div>
		</Layout>
	);
}

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: {language: {eq: $language}}) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;